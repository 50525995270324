.donwload-video-modal .modal-wrapper {
  background-color: white !important;
  box-shadow: none !important;

  top: 50%;
  height: 50%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: absolute;
  display: block;
}
