h1 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
}

h2 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
}

h3 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}

h4 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

h5 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
}

h6 {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  margin: 0;
}

p {
  font-family: 'SF Pro Display',"Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
}
