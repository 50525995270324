.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 662px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

/* 上のラベル */
.react-calendar__navigation__label {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}
.react-calendar__navigation__label__labelText--from,
.react-calendar__navigation__label__labelText--to {
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.react-calendar__navigation__label__divider {
  display: none;
}

.react-calendar__navigation__next-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
}
.react-calendar__tile {
  max-width: 40px !important;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 12px;
  background: none;
  border-radius: 50%;
  color: #2a2a2d;
  font-weight: bold;
  margin: 2px !important;
}
.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

/*  曜日部 */
.react-calendar__month-view__weekdays {
  margin-bottom: 8px;
  justify-content: center;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #d2d2d2;
}
.react-calendar__month-view__weekdays__weekday {
  width: 40px;
  max-width: 40px !important;
  color: #717171;
  margin: 2px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: rgba(230, 230, 230);
  color: black;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: #ffffa9; */
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active.has-schedule.event {
  background-color: #29cc97 !important;
}
.react-calendar__tile--active.has-schedule.seminar {
  background-color: #de4346 !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.has-schedule.seminar {
  border: 2px solid #de4346 !important;
}
.has-schedule.seminar:hover {
  color: white;
  background-color: #de4346 !important;
}

.has-schedule.event {
  border: 2px solid #29cc97 !important;
}
.has-schedule.event:hover {
  color: white;
  background-color: #29cc97 !important;
}

.react-calendar__month-view__days {
  justify-content: center;
}

.react-calendar__navigation__label__labelText {
  color: black;
}

@media screen and (max-width: 400px) {
  .react-calendar__tile {
    max-width: 46px !important;
    width: 46px;
    height: 46px;
    text-align: center;
    font-size: 12px;
    background: none;
    border-radius: 50%;
    color: #2a2a2d;
    font-weight: bold;
    margin: 2px !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    width: 46px;
    max-width: 46px !important;
    color: #717171;
    margin: 2px;
  }
}
