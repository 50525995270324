.rect-16_9 {
  position: relative;
}
.rect-16_9:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}
.rect-16_9 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}